/* common */
.heading {
	text-align: center;
	padding-top: 8.5rem;
	position: relative;

	@include mq("sp") {
		padding-top: 5rem;
	}

	&::before {
		position: absolute;
		content: "";
		display: inline-block;
		width: 2px;
		height: 54px;
		top: 0;
		left: 50%;
		transform: translateX(-50%);
		background-color: #000;

		@include mq("sp") {
			height: 30px;
		}
	}

	&--topic {
		flex-basis: 162px;

		@include mq("sp") {
			flex-basis: 100%;
			margin-bottom: 2rem;
		}
	}

	h2 {
		font-size: 2.67rem;
		letter-spacing: 0.21em;
		margin-bottom: 1rem;
	}

	span {
		display: block;
		font-size: 1.8rem;
		letter-spacing: 0.04em;
		margin-bottom: 6rem;

		@include mq("sp") {
			margin-bottom: 3rem;
		}
	}

	a {

		@include detail;
	}
}

.spnone {

	@include mq("sp") {
		display: none;
	}
}

/* mainVisual */

.mainVisual {
	margin-bottom: 5.5rem;
	overflow-x: hidden;

	&__wrapper {
		position: relative;
		margin-left: auto;
		margin-right: auto;
		max-width: 740px;
		max-height: 535px;
	}
}

/* スライダー設定 */

.swiper-container {
	width: 100%;
	height: 100%;
	overflow: visible;
	z-index: 1;

	@include mq("sp") {
		height: auto;
	}
}

.swiper-slide {
	margin-right: 0 !important;

	img {

		@include mq("sp") {
			width: 100%;
		}
	}
}

/* banner */

.banner {
	display: block;
	width: 830px;
	max-width: calc(100% - 10px);
	height: 136px;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 5.5rem;
	background: url("../img/park-banner.png") center center / cover no-repeat;

	&__gray {
		color: #fff;
		width: 830px;
		max-width: 100%;
		height: 136px;
		background-color: rgba(0, 0, 0, 0.3);
		padding-top: 4.5rem;
		padding-right: 4.5rem;
		text-align: right;

		@include mq("sp") {
			padding: 4rem 0 0;
			text-align: center;
		}
	}

	&__text {
		display: inline-block;
		text-align: center;

		h2 {
			font-size: 3rem;
			letter-spacing: 0.21em;
			margin-bottom: 1.5rem;
		}

		p {

			@include detail;
			border-bottom: 1px solid #fff;
		}
	}
}

/* topic */
.topic {

	&__wrapper {
		width: 830px;
		max-width: calc(100% - 10px);
		display: flex;
		justify-content: space-between;
		margin-bottom: 7.5rem;

		@include mq("sp") {
			flex-wrap: wrap;
			justify-content: center;
			margin-bottom: 4rem;
		}
	}

	&__content {
		flex-basis: 287px;

		@include mq("sp") {
			flex-basis: 450px;
			margin-bottom: 2rem;
		}

		a {
			display: block;
			margin-bottom: 1.5rem;

			img {
				width: 100%;
			}
		}
	}

	&__list {
		display: flex;
		justify-content: space-between;
	}

	&__left {
		padding-top: 0.5rem;

		time {
			display: block;
			font-weight: 300;
			font-size: 1.2rem;
			letter-spacing: 0.05em;
			margin-bottom: 1.5rem;
		}

		a {
			font-size: 1.6rem;
			margin-bottom: 0;
			letter-spacing: 0.21em;
			line-height: 1.5;
		}
	}

	&__right {
		text-align: center;

		p {
			background-color: #000;
			color: #fff;
			font-size: 1.2rem;
			margin-bottom: 3.5rem;
			padding: 0.5rem;
			letter-spacing: 0.1em;

			@include mq("sp") {
				margin-bottom: 2rem;
			}
		}

		a {
			border-left: 1px solid #000;
			border-right: 1px solid #000;
			font-size: 1.2rem;
			padding: 0.5rem 1.5rem;
			margin-bottom: 0;
		}
	}
}

/* experience */
.experience {

	&__wrapper {
		display: flex;

		@include mq("sp") {
			flex-wrap: wrap;
		}
	}

	&__item {
		background-size: cover;
		background-position: center center;
		background-repeat: no-repeat;
		flex-basis: 50%;
		padding-top: calc(326 / 550 / 2 * 100%);
		position: relative;
		text-align: center;

		@include mq("sp") {
			flex-basis: 100%;
			padding-top: calc(326 / 550 * 100%);
		}

		&--1 {
			background-image: url("../img/experience-bg1.png");
		}

		&--2 {
			background-image: url("../img/experience-bg2.png");
		}
	}

	&__gray {
		background-color: rgba(0, 0, 0, 0.3);
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	&__text {
		color: #fff;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);

		span {
			display: inline-block;
			font-size: 1.2rem;
			letter-spacing: 0.1em;
			margin-bottom: 1.5rem;
		}

		h3 {
			font-size: 2.33rem;
			letter-spacing: 0.21em;
			margin: 0 auto 3.5rem;
			line-height: 1.5;
			width: 255px;

			@include mq("sp") {
				width: 100%;
			}
		}

		p {
			font-size: 1.3rem;
			line-height: 2;
			letter-spacing: 0.11em;
			margin: 0 auto;
			width: 305px;
		}
	}
}

/* about */
.about {

	&__wrapper {
		padding: 135px 0 135px 200px;
		position: relative;

		@include mq("tab") {
			padding-left: 10%;
		}

		@include mq("sp") {
			margin: 0 auto;
			padding: 7rem 0;
			width: calc(100% - 10px);
			text-align: center;
		}
	}

	&__img {
		position: absolute;
		right: 0;
		bottom: -2rem;
		z-index: 1;

		@include mq("pc") {
			right: 3%;
		}

		@include mq("tab") {
			bottom: inherit;
			top: 50%;
			transform: translateY(-50%);
		}

		@include mq("sp") {
			z-index: -1;
		}

		img {
			width: 515px;

			@include mq("tab") {
				width: 335px;
			}

			@include mq("sp") {
				width: 260px;
			}
		}
	}

	&__heading {

		h2 {
			font-size: 2.67rem;
			line-height: 2;
			letter-spacing: 0.21em;
			margin-bottom: 2rem;
			width: 295px;

			@include mq("sp") {
				width: 100%;
			}
		}

		span {
			display: inline-block;
			font-size: 1.8rem;
			letter-spacing: 0.04em;
			margin-bottom: 3.5rem;
		}

		p {
			font-size: 1.3rem;
			line-height: 2;
			letter-spacing: 0.11em;
			width: 350px;
			margin-bottom: 4rem;

			@include mq("sp") {
				width: 100%;
			}
		}

		a {

			@include detail;
		}
	}
}

/* works */
.works {

	&__visual {
		background: url("../img/works-bg.png") center center / cover no-repeat;
		padding-top: calc(486 / 1100 * 100%);
		position: relative;
		margin-bottom: 8rem;

		@include mq("sp") {
			margin-bottom: 5rem;
		}
	}

	&__heading {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		color: #fff;
		text-align: center;

		h2 {
			font-size: 2.67rem;
			letter-spacing: 0.21rem;
			margin-bottom: 1rem;
		}

		span {
			display: inline-block;
			font-size: 1.8rem;
			letter-spacing: 0.04em;
			margin-bottom: 3rem;

			@include mq("sp") {
				margin-bottom: 1.5rem;
			}
		}

		p {
			font-size: 1.3rem;
			letter-spacing: 0.11rem;
			line-height: 2;
			width: 300px;
		}
	}

	&__content {
		width: 740px;
		max-width: calc(100% - 10px);
		margin: 0 auto 8rem;
		display: flex;
		justify-content: space-between;

		@include mq("sp") {
			flex-wrap: wrap;
			justify-content: center;
			margin-bottom: 5rem;
		}
	}

	&__img {
		display: block;
		flex-basis: 87%;

		@include mq("sp") {
			flex-basis: 100%;
		}

		img {
			width: 100%;
		}

		&--1 {

			@include mq("sp") {
				order: 1;
			}
		}

		&--2 {
			order: 1;
		}
	}

	&__text {
		display: inline-block;
		flex-basis: 10%;
		padding-top: 6.5rem;
		position: relative;
		writing-mode: vertical-rl;

		@include mq("sp") {
			flex-basis: 100%;
			padding-top: 5rem;
			text-align: center;
			writing-mode: horizontal-tb;
		}

		&::before {
			position: absolute;
			content: "";
			display: inline-block;
			width: 2px;
			height: 54px;
			top: 0;
			right: 16%;
			background-color: #000;

			@include mq("sp") {
				height: 30px;
				right: inherit;
				left: 50%;
				transform: translateX(-50%);
			}
		}

		h3 {
			font-size: 2.67rem;
			letter-spacing: 0.21em;
			margin-left: 1rem;

			@include mq("sp") {
				margin-bottom: 1rem;
			}
		}

		p {
			font-size: 1.8rem;
			letter-spacing: 0.04em;

			@include mq("sp") {
				margin-bottom: 2rem;
			}
		}

		&--1 {

			@include mq("sp") {
				order: 0;
			}
		}

		&--2 {
			order: 0;
			padding-right: 2rem;

			@include mq("sp") {
				padding-right: 0;
			}

			&::before {
				right: 43%;

				@include mq("sp") {
					right: inherit;
				}
			}
		}
	}
}

/* house */
.house {

	&__content {
		width: 1100px;
		max-width: calc(100% - 10px);
		display: flex;
		justify-content: space-between;
		margin: 0 auto 6rem;

		@include mq("sp") {
			flex-wrap: wrap;
			justify-content: center;
			margin-bottom: 10rem;
		}

		&--1 {

			@include mq("pc") {
				padding-left: 7rem;
			}
		}

		&--2 {

			@include mq("pc") {
				padding-right: 7rem;
			}
		}
	}

	&__img {
		flex-basis: 26%;

		@include mq("sp") {
			flex-basis: 450px;
			margin-bottom: 2rem;
		}

		img {
			margin-bottom: 2.5rem;
			width: 100%;
		}

		p {
			font-size: 1.2rem;
			font-weight: 300;
			line-height: 2;
			letter-spacing: 0.05em;
		}

		&--2 {
			order: 1;

			@include mq("sp") {
				order: 0;
			}
		}
	}

	&__map {
		flex-basis: 65%;
		position: relative;

		@include mq("tab") {
			flex-basis: 72%;
		}

		@include mq("sp") {
			flex-basis: 450px;
		}

		> img {
			width: 100%;
			height: 100%;

			@include mq("sp") {
				height: auto;
			}
		}

		&--2 {
			order: 0;

			@include mq("sp") {
				order: 1;
			}
		}
	}

	&__address {
		position: absolute;
		bottom: 0;
		width: 244px;
		background-color: #fff;
		padding: 3.5rem 4rem 2rem;

		@include mq("sp") {
			border: solid 1px #000;
			padding: 1rem 3rem 1rem 1rem;
			position: static;
			width: 100%;
		}

		&--1 {
			right: 8%;

			@include mq("tab") {
				right: 5%;
			}
		}

		&--2 {
			left: 8%;

			@include mq("tab") {
				left: 5%;
			}
		}

		p {
			font-size: 2rem;
			line-height: 1.5;
			letter-spacing: 0.29rem;
			margin-bottom: 1.5rem;

			@include mq("sp") {
				margin-bottom: 1rem;
			}
		}

		address {
			font-size: 1.2rem;
			font-weight: 300;
			line-height: 1.5;
			margin-bottom: 2.5rem;

			@include mq("sp") {
				margin-bottom: 1.5rem;
			}
		}
	}

	&__link {
		display: flex;
		justify-content: space-between;

		a:first-child {

			@include detail;
		}

		a:last-child {
			border-left: 1px solid #000;
			border-right: 1px solid #000;
			font-size: 1.2rem;
			letter-spacing: 0.1em;
			padding: 0.5rem 1rem;

			@include mq("sp") {
				padding: 0.3rem 0.7rem;
			}

			&:hover {
				opacity: 0.6;
			}

			img {
				width: 8px;
				margin-right: 0.5rem;
			}
		}
	}
}

/* blog */
.blog {

	&__wrapper {
		padding-bottom: 9rem;

		@include mq("sp") {
			padding-bottom: 5rem;
		}
	}

	&__list {
		width: 950px;
		max-width: calc(100% - 10px);
		margin: 0 auto;
		display: flex;
		justify-content: space-between;

		@include mq("sp") {
			flex-wrap: wrap;
			justify-content: space-evenly;
		}
	}

	&__item {
		flex-basis: 22%;

		@include mq("sp") {
			flex-basis: 300px;
			padding: 2rem;
		}
	}

	&__img {
		display: block;
		margin-bottom: 1.5rem;

		img {
			width: 100%;
		}
	}

	&__title {
		margin-bottom: 1rem;

		time {
			display: block;
			font-size: 1.2rem;
			font-weight: 300;
			letter-spacing: 0.05em;
			margin-bottom: 1rem;
		}

		a {
			display: inline-block;
			font-size: 1.4rem;
			letter-spacing: 0.06em;
		}
	}

	&__staff {
		margin-bottom: 1.5rem;

		img {
			display: inline-block;
			width: 34px;
			margin-right: 1rem;
		}

		span {
			font-size: 1.2rem;
			letter-spacing: 0.05em;
			line-height: 3;
			vertical-align: top;
		}
	}

	&__text {

		p {
			font-size: 1.2rem;
			font-weight: 300;
			line-height: 2;
		}
	}
}

/* fix */
.fix {
	position: fixed;
	top: 50%;
	right: 0;
	transform: translateY(-50%);
	z-index: 10;

	@include mq("sp") {
	}

	&__reserve {
		background-color: #000;
		color: #fff;
		display: block;
		width: 48px;
		height: 132px;
		padding-top: 1.5rem;
		text-align: center;
		margin-bottom: 1.5rem;
		position: relative;

		@include mq("sp") {
			width: 40px;
			height: 110px;
			margin-bottom: 1rem;
		}

		&:hover {
			color: #fff;
		}

		img {
			width: 22px;
			margin-bottom: 2rem;
		}

		p {
			writing-mode: vertical-rl;
			font-size: 1.6rem;
			letter-spacing: 0.05em;
			position: absolute;
			top: 38%;
			left: 50%;
			transform: translateX(-50%);
		}
	}

	&__document {
		background-color: #000;
		color: #fff;
		display: block;
		width: 48px;
		height: 132px;
		padding-top: 1.5rem;
		text-align: center;
		margin-bottom: 3.5rem;
		position: relative;

		@include mq("sp") {
			width: 40px;
			height: 110px;
			margin-bottom: 2rem;
		}

		&:hover {
			color: #fff;
		}

		img {
			width: 28px;
			margin-bottom: 2rem;
		}

		p {
			writing-mode: vertical-rl;
			font-size: 1.6rem;
			letter-spacing: 0.05em;
			position: absolute;
			top: 38%;
			left: 50%;
			transform: translateX(-50%);
		}
	}

	&__pageTop {
		background-color: #fff;
		display: block;
		width: 48px;
		height: 48px;
		position: relative;

		@include mq("sp") {
			width: 40px;
			height: 40px;
		}

		&::after {
			content: "";
			display: inline-block;
			position: absolute;
			width: 15px;
			height: 15px;
			top: 70%;
			left: 50%;
			border-top: 2.4px solid #000;
			border-left: 2.4px solid #000;
			transform: translate(-50%, -70%) rotate(45deg);

			@include mq("sp") {
				width: 10px;
				height: 10px;
				top: 60%;
				transform: translate(-50%, -60%) rotate(45deg);
			}
		}
	}
}

/* footer */
.footer {

	&__wrapper {
		background-color: rgba(0, 0, 0, 0.09);
		padding-top: 7rem;
		padding-bottom: 1.5rem;
		text-align: center;

		@include mq("sp") {
			padding-top: 3rem;
		}
	}

	&__heading {

		p {
			font-size: 0.97rem;
			letter-spacing: 0.05em;
			margin-bottom: 1rem;
		}

		h2 {
			margin-bottom: 3rem;

			@include mq("sp") {
				margin-bottom: 2rem;
			}

			img {
				width: 254px;
			}
		}
	}

	&__address {
		font-size: 1.1rem;
		font-weight: 300;
		letter-spacing: 0.05em;
		line-height: 2;
		margin-bottom: 4.5rem;

		@include mq("sp") {
			margin-bottom: 2.5rem;
		}
	}

	&__nav {
		margin-bottom: 6rem;

		@include mq("sp") {
			margin-bottom: 3rem;
		}
	}

	&__list {
		letter-spacing: -0.4rem;
	}

	&__item {
		display: inline-block;
		letter-spacing: normal;
	}

	&__link {
		border-left: 1px solid #000;
		font-size: 1.2rem;
		letter-spacing: 0.05em;
		padding: 0.5rem 1.5rem;

		@include mq("sp") {
			font-size: 1rem;
			padding: 0.5rem 0.8rem;
		}

		&--last {
			border-right: 1px solid #000;
		}
	}

	&__copyRight {
		font-size: 0.97rem;
		letter-spacing: 0.05em;
	}
}
