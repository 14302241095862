* {
	box-sizing: border-box;
}

html {
	font-size: 62.5%; /* 10px */

	@include mq("sp") {
		font-size: 43.75%;
	}
}

body {
	font-family: "Hiragino Kaku Gothic ProN", poppins, sans-serif;
	font-size: 1.6rem; /* 16px */
	font-weight: 600;
}

.wrapper {
	width: 1100px;
	max-width: 100%;
	margin: 0 auto;
}

a {
	text-decoration: none;
	color: #000;

	&:hover {
		color: rgba(0, 0, 0, 0.6);
	}
}
