.header {

	&__wrapper {
		display: flex;
		justify-content: space-between;
		padding: 0 4.4rem 2rem 4rem;

		@include mq("sp") {
			padding: 0 1rem 1rem;
		}
	}

	&__logo {
		padding-top: 3.5rem;

		@include mq("sp") {
			padding-top: 1.5rem;
		}
	}

	&__logoImg {
		padding-right: 1rem;
		vertical-align: bottom;
	}

	&__logoText {
		display: inline-block;
		vertical-align: bottom;

		p {
			font-size: 0.8rem;
			margin-bottom: 0.5rem;

			@include mq("sp") {
				font-size: 0.5rem;
			}
		}

		img {
			width: 150px;

			@include mq("sp") {
				width: 100px;
			}
		}
	}

	&__nav {
		text-align: right;
	}

	&__navTop {
		margin-bottom: 1.8rem;

		@include mq("tab") {
			margin-bottom: 1rem;
		}

		@include mq("sp") {
			display: none;
		}
	}

	&__sns {
		display: inline-block;
		padding-top: 1.5rem;

		a {
			display: inline-block;
			width: 25px;
			margin-right: 1.5rem;

			&:hover {
				opacity: 0.6;
			}

			img {
				width: 100%;
			}
		}
	}

	&__menu {
		display: inline-block;
		vertical-align: top;
		letter-spacing: -0.4rem;

		a {
			display: inline-block;
			font-size: 1.2rem;
			padding: 2rem 2.6rem 1rem;
			border-left: 1px solid #000;
			letter-spacing: normal;

			&:last-child {
				border-right: 1px solid #000;
			}
		}
	}

	&__spMenu {
		display: none;

		@include mq("tab") {
			border: 1px solid #000;
			border-radius: 5px;
			cursor: pointer;
			display: inline-block;
			padding: 10px;
		}

		@include mq("sp") {
			margin-top: 2rem;
		}

		span {
			content: "";
			background-color: #000;
			display: inline-block;
			width: 20px;
			height: 3px;
			vertical-align: middle;
			position: relative;

			&::before,
			&::after {
				content: "";
				background-color: #000;
				width: 20px;
				height: 3px;
				position: absolute;
				left: 0;
			}

			&::before {
				top: -6px;
			}

			&::after {
				top: 6px;
			}
		}
	}

	&__list {

		@include mq("tab") {
			position: fixed;
			background-color: #fff;
			width: 300px;
			height: 100vh;
			padding: 1rem;
			top: 0;
			right: -300px;
			text-align: left;
			z-index: 99;
		}
	}

	&__spCloseIcon {
		display: none;

		@include mq("tab") {
			display: block;
			height: 50px;

			&--icon {
				cursor: pointer;
				display: inline-block;
				width: 25px;
				height: 20px;
				position: relative;

				&::before,
				&::after {
					position: absolute;
					content: "";
					width: 25px;
					height: 1px;
					top: 10px;
					background-color: #000;
				}

				&::before {
					left: 0;
					transform: rotate(45deg);
				}

				&::after {
					right: 0;
					transform: rotate(-45deg);
				}
			}
		}
	}

	&__item {
		display: inline-block;
		margin-right: 2rem;

		@include mq("tab") {
			display: block;
			margin-right: 0;
		}

		&--pclast {
			margin-right: 0;
		}

		&--sp {
			display: none;

			@include mq("sp") {
				display: block;
			}

			&--sns {

				@include mq("sp") {
					margin-top: 1rem;

					a {
						display: inline-block;
						width: 25px;
						margin-right: 1rem;

						&:hover {
							opacity: 0.6;
						}

						img {
							width: 100%;
						}
					}
				}
			}
		}
	}

	&__link {
		font-size: 1.2rem;

		@include mq("tab") {
			border-top: 1px solid #000;
			display: block;
			padding: 1.5rem 0;
		}

		@include mq("sp") {
			padding: 1.2rem 0;
		}

		&--pclast {

			@include mq("tab") {
				border-bottom: 1px solid #000;
			}

			@include mq("sp") {
				border-bottom: 0;
			}
		}

		&--sp {

			&--last {

				@include mq("sp") {
					border-bottom: 1px solid #000;
				}
			}
		}
	}
}
