@mixin mq($breakpoint: sp) {

	@media #{map-get($breakpoints, $breakpoint)} {
		@content;
	}
}

@mixin detail {
	display: inline-block;
	font-size: 1.2rem;
	padding-bottom: 1rem;
	border-bottom: 1px solid #000;
}
